.blue-angle {
    width: 100vw;
    height: 100px;
    position: fixed;
    top: -30px;
    left: 0;
    background-color: #FF6B1B;
    opacity: 0.95;
    transform:  rotate(2deg) skew(2deg);
    -webkit-transform: rotate(2deg) skew(2deg);
    -moz-transform: rotate(2deg) skew(2deg);
    -o-transform:  rotate(2deg) skew(2deg);
    -ms-transform:  rotate(2deg) skew(2deg);
    z-index: 3999;
}

.header-wrapper {
    width: 100vw;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    top: -125px;
    left: 0;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    z-index: 99999;
    transform: rotate(-2deg) skew(-2deg);
    -webkit-transform: rotate(-2deg) skew(-2deg);
    -moz-transform: rotate(-2deg) skew(-2deg);
    -o-transform: rotate(-2deg) skew(-2deg);
    -ms-transform: rotate(-2deg) skew(-2deg);
}

.header-inner-container {
    position: relative;
    top: 30px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    height: 120px;
}

.header-links-container {
    position: absolute;
    top: 50px;
    right: 10px;
}

.header-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-logo-container {
    width: 85px;
    height: 85px;
    margin-top: -30px;
    fill: #178BAC;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .header-wrapper {
        top: -140px;
    }

    .blue-angle {
        top: -45px;
    }

    .header-logo-container {
        width: 55px;
        height: 55px;
        margin-top: -10px;
    }

    .header-links-container {
        visibility: hidden;
    }
}

.header-title {
    font-size: 2rem;
    margin: -9px 0 0 5px;
    color:#035870;
}

.header-link {
    position: relative;
    font-size: 1.1rem;
    font-weight: 600;
    float: right;
    margin-left: 25px;
    color: #484747 !important;
    cursor: pointer;
    opacity: 0.75;
    text-decoration:none !important;


}

.header-link:hover {
    opacity: 1;
}

.header-link::before {
    width: 0%;
    left: 2px;
    bottom: -20px;
    transition: 300ms;
    height: 5px;
    content: "";
    position: absolute;
    background-color: #178BAC;
}
  
.header-link:hover::before {
    width: 100%;
}