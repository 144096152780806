.App {
}
.home {
  overflow: hidden;
  min-height: 100%;
}
.career {
  overflow: hidden;
  min-height: 100%;

}
.contact {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
}
.about-us{
  overflow: hidden;
  min-height: 100%;
}
.privacy-policy{
  overflow: hidden;
  min-height: 100%;
}
.solutions{
  overflow: hidden;
  min-height: 100%;
}
.terms-of-use{
  overflow: hidden;
  min-height: 100%;
}

.apply-now{
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
}

.test {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
}