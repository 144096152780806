.banner-wrapper {
    height: 100vh;
    max-height: 1000px;
    width: 100vw;
    margin: 35px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('banner1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.textwrap{
  width:100%;
  height:100%;
  opacity:100;
  z-index:10000;
}

.banner-background {
    height: 120%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

@media only screen and (min-width: 1000px) {
  .banner-wrapper {
	background-attachment: fixed;
	}
}

.banner-background-container {
    width: 110vw;
    margin-left: -5vw;
    position: absolute;
    top: 0;
    left: 0;
    fill: rgba(23,139,172,0.05);
}

.circut-container {
    position: absolute;
}

.circut-left {
    width: 50vw;
    height: 100vh;
    top: 2vh;
    left: -7vw;
    fill: rgba(23,139,172,0.2);
}

.circut-right {
    width: 50vw;
    height: 70vh;
    top: 2vh;
    right: -12vw;
    fill: rgba(23,139,172,0.1);
}

.page-title {
    max-width: 95vw;
    text-align: center;
    margin: -8% auto 8px auto;
    border-bottom: 2px solid rgba(255,255,255,1);
    font-size: 2rem;
    color: #1761C2;
    z-index: 2;
    text-shadow: -1px 2px 2px rgba(255, 255, 255,0.8);
}

.page-text {
    max-width: 75vw;
    text-align: center;
    margin: 3% auto 8px auto;
    font-size: 1.5rem;
    color: grey;
    z-index: 2;
}

.banner-image {
    width: 45px;
    height:45px;
    background-image: url('procore.png');  
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    margin-right:10px;
    border:none;
}

.banner-button {
  font-family: 'Roboto';
    margin-top: 50px;
	padding: 20px 40px;
	background: none;
	border: none;
	position: relative;
	text-transform: uppercase;
	color: #FF6B1B;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    z-index: 2;
}

.banner-button-text {
    color: #FF6B1B;
}

.banner-button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid white;
    transition: transform .2s ;
    transform: translate(3px, 3px);
}

.banner-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid white;
    transition: transform .2s;
    transform: translate(-3px, -3px);
}

.banner-button:hover:after {
    transform: translate(0);
}

.banner-button:hover:before {
    transform: translate(0);
}

.page-text a:link {
    color: #1761C2;
  }
  
.page-text a:visited {
    color: #1761C2;
  } */

.page-text a {
    color: #1761C2!important
}