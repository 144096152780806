.top-wrapper{
    width: 100%;
    position: relative;
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    justify-content:left;
    margin-bottom: 100px;
    margin-top: 40px;
    z-index:100;
    flex:1;
}

.recaptchaa{
  z-index: 110000;
  position:relative;
}

.contact-collect-wrapper{
    min-height:100%;
    flex:1;
    position:relative;
    z-index:100;
}

.top-text-wrapper{
    position: relative;
    margin-left: 10%;
    margin-right:0px;
    margin-top: 30px;
    flex:left;
}

.contact-info-wrapper {
    left: 10%;
    margin-left: 0;
    margin-top: 30px;
    margin-right: 21%;
    position: relative;
    width:50%;
    flex:left;
    overflow:visible;
}

.input-form{
    width:160%;
}

.cta-txt{
    font-family: 'Karla';
    font-size: 20px;
    font-color: black;
    font-weight: 10;
    letter-spacing: 4px;
}

.ty-txt{
    font-family: 'Karla';
    font-size: 25px;
    color: black;
    font-weight: 200;
    letter-spacing: 2px;
}


.cont-title{
    font-family: "Karla";
    font-size: 45px;
    font-color: black;
    font-weight: 600;
    position: relative;
    margin-top: 10px;
    margin-left: -2px;
}

.cont-title::after {
    display: block;
    position: relative;
    bottom: -.6em;
    margin-bottom: 10px;
    margin-left:2px;
    content: "";
    width: 60px;
    height: 5px;
    background-color: #FF6B1B;
}

.supp-text-wrapper{
    margin-top:60px;
    position:relative;
}

.supp-text{
    font-family: "karla";
    position: relative;
    font-size: 16px;
    font-weight: normal;
}

.supp-bold-text{
    font-family: "karla";
    position: relative;
    font-size: 16px;
    font-weight: bold;
}

.input-header{
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    position: relative;
    margin-bottom: 0px;
}

.firstLast{
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 200;

    color: #A4a4a4;
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    margin-top: -4px;
}

.reg-box{
  width: 100%;
  padding: 12px; /* Some padding */
  background-color: #eeeeee;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 0px; /* Add a top margin */
  margin-right: 20px;
  font-family: "Roboto";
  position: relative;
  border:none;
  background-color: #eeeeee;
}

.big-box{
  width: 100%;
  padding: 12px; /* Some padding */
  border-color: #eeeeee;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 0px; /* Add a top margin */
  margin-right: 20px;
  position: relative;
  background-color: #eeeeee;
}

.tbl-cell{
    width: 25%;
}

.big-cell{
    width: 27%;
}

.submit-button{
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 400;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    border-style: solid;
    border-width: 1px;
    border-color: #CCC;
    border-radius: 0px;
    background-color: white;
}

.recaptcha{
    z-index: 100;
}