.hamburger-container {
    height: 35px;
    width: 35px;
    position: absolute;
    top: 40px;
    right: 0px;
    fill: #3D3D3D;
    transform: rotate(2deg) skew(2deg);
    -webkit-transform: rotate(2deg) skew(2deg);
    -moz-transform: rotate(2deg) skew(2deg);
    -o-transform: rotate(2deg) skew(2deg);
    -ms-transform: rotate(2deg) skew(2deg);
    cursor: pointer;
}

@media screen and (min-width: 850px) {
    .hamburger-container {
        visibility: hidden;
    }
}

@media screen and (max-width: 850px) {
    .hamburger-container {
        top: 46px;
    }
}

.menu-container {
    width: 300px;
    max-width: 0px;
    margin-top: 155px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    transition: max-width 300ms ease-out;
    background-color: white;
    box-shadow: -2px 0 10px rgba(0,0,0,0.4);
    z-index: 9999;
}

.menu-container-open {
    max-width: 300px;
    transition: max-width 300ms ease-out;
}

.menu-inner-container {
    width: 100%;
    margin-top: 50px;
    position: relative;
    text-align: center;
    transform: rotate(2deg) skew(2deg);
    -webkit-transform: rotate(2deg) skew(2deg);
    -moz-transform: rotate(2deg) skew(2deg);
    -o-transform: rotate(2deg) skew(2deg);
    -ms-transform: rotate(2deg) skew(2deg);
}

.menu-close {
    position: absolute;
    top: -30px;
    left: 20px;
    font-size: 1.5rem;
    cursor: pointer;
}

.menu-tab-container {
    height: 40px;
    width: 90%;
    margin: 20px 0 0 5%;
    border-bottom: 1px solid rgb(72,72,72);
}

.menu-overlay {
    height: 100vh;
    width: 100vw;
    margin-top: 125px;
    position: fixed;
    top: 0;
    left: 0;
    transform: rotate(2deg) skew(2deg);
    -webkit-transform: rotate(2deg) skew(2deg);
    -moz-transform: rotate(2deg) skew(2deg);
    -o-transform: rotate(2deg) skew(2deg);
    -ms-transform: rotate(2deg) skew(2deg);
    z-index: 9998;
}