@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lora&display=swap);

* {
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

*:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: 'Karla', sans-serif,
    monospace;
}
