.wrapper{
  margin-top: 100px;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 25px;

}

.title{
  font-size: 50px;
}

