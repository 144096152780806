.footer-wrapper {
  background-color: #1761C2;
  position:relative;
  z-index: 99;
  padding: 15px 0 5px;
  width: 100%;
}

.footer-inner-container{
    display:flex;
    flex-wrap:wrap;
    position: relative;
    width:80%;
    margin: auto;
    max-width: 1080px;
}

.bottom-footer-text-container{
    float:none;
    text-align:center;
    padding-bottom:10px;
    display: block;
}

.footer-links-container{
    display: inline;
}


.bottom-footer-text{
    display: inline;
    font-weight: normal;
}

.bottom-footer-sep{
  display:inline;
  font-weight:normal;
  color:white;
  margin-left:7.5px;
  opacity:.75;
}

.footer-link{
    position: relative;
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 7.5px;
    color: white;
    cursor: pointer;
    opacity: 0.75;
}

.footer-link:hover {
    opacity: 1;
}

.media-link-container{

  margin:auto;
  margin-bottom: 5px;
  margin-top: -5px;
  padding-left:43.5px;
  padding-right:56.5px;
  list-style: inside;
  order:2;
  display:block;
  width: 256px;
}

.facebook-logo-container{
  background-image: url('./f_logo_RGB-White_250.png');
  display:inline-block;
  margin-left:20px;
  height: 30px;
  width: 30px;
  background-size: contain;
  opacity:.75;
}

.twitter-logo-container{
  background-image: url('./twitter_logo_white.png');
  display:inline-block;
  margin-left:20px;
  margin-right:-7px;
  height: 30px;
  width: 30px;
  background-size: contain;
  opacity:.75;
}

.linkedin-logo-container{
  background-image: url('./linkedin-3-128.png');
  display:inline-block;
  margin-left:20px;
  height: 30px;
  width: 30px;
  background-size: contain;
  opacity:.75;
}

.facebook-logo-container:hover{
  opacity: 1
}

.twitter-logo-container:hover{
  opacity: 1
}

.linkedin-logo-container:hover{
  opacity: 1
}